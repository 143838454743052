import {UPDATE, UPDATE_ALL_ORDERS, ALL_ORDERS} from './type';

const intialState = {order_id: null, orderIds: [], balance: null, orderDetails: []};

export const OrdersReducer = (state = intialState, action) => {
  switch (action.type) {
    case UPDATE:
      return {...state, order_id: action.payload.order_id, balance: action.payload.balance};
    case UPDATE_ALL_ORDERS:
      return {...state, orderIds: action.payload};
    case ALL_ORDERS:
      return {...state, orderDetails: action.payload};
    default:
      return {...state};
  }
};
