class Publisher {
    constructor() {
      this.listener = [];
    }
  
    subscribe(handler) {
      this.listener.push(handler);
    }
  
    unsubscribeAll() {
      this.listener = [];
    }
  
    on(action, payload) {
      if (typeof action !== "string" && !Array.isArray(action)) return;
  
      let actionList = action;
      if (typeof action === "string") actionList = [action];
  
      actionList.forEach(a => {
        this.listener.forEach(handler => {
          handler(a, payload);
        });
      });
    }
  }
  
  export default Publisher;