import { getUserToken } from '../../webStorage/localStorage';
import {
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
} from './userDetailsTypes';

const intialState = {
  loading: false,
  Details: {
    data: {
      token: getUserToken(),
    },
  },
  error: '',
  isLoaded: getUserToken() ? true : false,
};
const fetchUserDetails = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        Details: action.payload,
        isLoaded: true,
        error: '',
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        Details: {},
        error: action.payload,
        isLoaded: true,
      };
    default:
      return { ...state };
  }
};

export default fetchUserDetails;
