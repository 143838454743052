import { getDefaultOrderType } from '../../functions/orderTypeFunctions';
import {
  DELIVERY_TYPE,
  DINEIN_TYPE,
  PICKUP_TYPE,
  SCHEDULED,
} from '../../webStorage/keys/sessionKeys';
import { getOrderType } from '../../webStorage/manageOrderType';
import { ASAP, CALENDAR, DELIVERY, DINING, RESET_STORE, TAKEAWAY } from './orderTypeTypes';

const intialState = {
  orderType: getOrderType() || getDefaultOrderType(),
  orderTime: ASAP,
};

const fetchOrderTypeOrderTime = (state = intialState, action) => {
  switch (action.type) {
    case DINING:
      return {
        orderType: DINEIN_TYPE,
        orderTime: ASAP,
      };
    case DELIVERY:
      return {
        ...state,
        orderType: DELIVERY_TYPE,
      };
    case TAKEAWAY:
      return {
        ...state,
        orderType: PICKUP_TYPE,
      };
    case ASAP:
      return {
        ...state,
        orderTime: ASAP,
        ...action.payload,
      };
    case CALENDAR:
      return {
        ...state,
        orderTime: SCHEDULED,
        ...action.payload,
      };
    case RESET_STORE:
      return intialState;
    default:
      return { ...state };
  }
};

export default fetchOrderTypeOrderTime;
