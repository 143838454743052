import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getUserToken } from '../webStorage/localStorage';
import RootReducers from './rootReducers';
import createSagaMiddleware from 'redux-saga'
import watchFetchRest from './home/saga';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const userToken = getUserToken();
const sagaMiddleware = createSagaMiddleware()

const store = createStore(RootReducers, composeEnhancers(applyMiddleware(thunk),applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchFetchRest)
export default store;
