import {
  ADDRESS_ERROR,
  FETCH_USER_ADDRESS_FAILURE,
  FETCH_USER_ADDRESS_REQUEST,
  FETCH_USER_ADDRESS_SUCCESS,
  FETCH_USER_DISTANCE,
  SET_DEFAULT_ADDRESS,
} from './userAddressTypes';

const intialState = {
  loading: true,
  userAddress: [],
  userDistance: '',
  error: '',
  isValid: true,
};

export const fetchuserAddressReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_USER_ADDRESS_REQUEST:
      return {
        loading: true,
      };
    case FETCH_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        userAddress: action.payload,
        error: '',
      };
    case FETCH_USER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        Setting: [],
        error: action.payload,
      };
    case FETCH_USER_DISTANCE:
      return {
        ...state,
        userDistance: action.payload,
      };
    case SET_DEFAULT_ADDRESS:
      return {
        ...state,
      };
    case ADDRESS_ERROR:
      return {
        ...state,
        isValid: action.payload,
      };
    default:
      return { ...state, loading: false };
  }
};

export default fetchuserAddressReducer;
