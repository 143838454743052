const AUTH_TOKEN = 'auth_token';
const USER_ID = 'user_id';
const USER_STATUS = 'user_status';
const RCS = 'rcs';

export const removeLocalItem = (item) => localStorage.removeItem(item);
export const setLocalItem = (key, value) => localStorage.setItem(key, value);
export const getLocalItem = (item) => localStorage.getItem(item);

export const setUserToken = (token) => typeof token == 'string' && setLocalItem(AUTH_TOKEN, token);
export const getUserToken = (_) => getLocalItem(AUTH_TOKEN);
export const removeUserToken = () => removeLocalItem(AUTH_TOKEN);
export const isGuestUser = () => getLocalItem('user_status') === 'GUEST';

export const setRcsToken = (rcs) => typeof rcs == 'string' && setLocalItem(RCS, rcs);
export const getRcsToken = () => getLocalItem(RCS);

export const setUserId = (userId) => setLocalItem(USER_ID, userId);
export const getUserId = () => getLocalItem(USER_ID);
export const removeUserId = () => removeLocalItem(USER_ID);

export const setGuestUser = (token, userId, status) => {
  setLocalItem(AUTH_TOKEN, token);
  setLocalItem(USER_ID, userId);
  setLocalItem(USER_STATUS, status);
};
export const removeUserStatus = () => removeLocalItem(USER_STATUS);
