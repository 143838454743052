export const redirectPathGenerator = (price, order_id, mall, paymentType, fc_order_id) => {
  const basePath = `${mall ? '/fc/' + mall : ''}${
    fc_order_id
      ? `/payment/response?isKiosk=1&amount=${price}&paymentType=${paymentType}&`
      : `/payment/response?status=1&amount=${price}&paymentType=${paymentType}&`
  }`;
  // conditional redirect path - foodcourt and normal restaurnat
  return mall
    ? fc_order_id
      ? `${basePath}fc_order_id=${fc_order_id}&fc=${mall}&redirect=1`
      : `${basePath}fc_order_id=${order_id}&fc=${mall}&redirect=1`
    : `${basePath}order_id=${order_id}&redirect=1`;
};
