// provide location relative to component folder
export const routes = [
  {
    name: 'Menu',
    location: '/menu/menu',
    path: '/r/:name/:ot?/:pickupType?',
    comp: () => import(/* webpackChunkName:"menu-page" */ `../component/menu/menu`),
  },
  {
    name: 'PrivacyPolicy',
    location: '/footer/privacyPolicy/privacyPolicy',
    comp: () =>
      import(
        /* webpackChunkName:"privacy-policy-page" */ `../component/footer/privacyPolicy/privacyPolicy`
      ),
    path: '/privacy-policy',
  },
  {
    name: 'Return',
    location: '/footer/return/return',
    comp: () => import(/* webpackChunkName:"return-page" */ `../component/footer/return/return`),
    path: '/return-refund',
  },
  {
    name: 'Terms',
    location: '/footer/terms&condition/terms',
    comp: () =>
      import(
        /* webpackChunkName:"terms-n-condition-page" */ `../component/footer/terms&condition/terms`
      ),
    path: '/terms-condition',
  },
  {
    name: 'QrCode',
    location: '/qrCode',
    comp: () => import(/* webpackChunkName:"qrcode-page" */ `../component/qrCode`),
    path: '/qr',
  },
  {
    name: 'QrError',
    location: '/qrError',
    comp: () => import(/* webpackChunkName:"qrcode-page" */ `../component/qrError`),
    path: '/qrError',
    exact: true,
  },
  {
    name: 'Cart',
    location: '/cart/cart',
    comp: () => import(/* webpackChunkName:"cart-page" */ `../component/cart/cart`),
    path: '/cart/:name/:ot?/:pickupType?',
  },
  {
    name: 'Payments',
    location: '/Payments',
    comp: () => import(/* webpackChunkName:"payment-page" */ `../component/Payments`),
    path: '/payments/:order_id?/:price?/:orderIds?/:payment_id?',
  },
  {
    name: 'Login',
    location: '/login/login',
    comp: () => import(/* webpackChunkName:"login-page" */ `../component/login/login`),
    path: '/login',
  },
  {
    name: 'Signup',
    location: '/login/Signup',
    comp: () => import(/* webpackChunkName:"signup-page" */ `../component/login/Signup`),
    path: '/signup',
  },
  {
    name: 'OrderStatus',
    location: '/OrderStatus',
    comp: () => import(/* webpackChunkName:"orderstatus-page" */ `../component/OrderStatus`),
    path: '/orderstatus/order_id/:order_id',
  },
  {
    name: 'Profile',
    location: '/profile/Profile',
    comp: () => import(/* webpackChunkName:"profile-page" */ `../component/profile/Profile`),
    path: '/profileHome',
  },
  {
    name: 'UserData',
    location: '/profile/userData',
    comp: () => import(/* webpackChunkName:"userdata-page" */ `../component/profile/userData`),
    path: '/user',
  },
  {
    name: 'NewLoyalty',
    location: '/profile/newLoyalty',
    comp: () => import(/* webpackChunkName:"newloaylty-page" */ `../component/profile/newLoyalty`),
    path: '/newLoyalty',
  },
  {
    name: 'OrderHistory',
    location: '/profile/orderHistory',
    comp: () =>
      import(/* webpackChunkName:"order-history-page" */ `../component/profile/orderHistory`),
    path: '/orderHistory',
  },
  {
    name: 'TableBookings',
    location: '/profile/table-bookings',
    comp: () =>
      import(/* webpackChunkName:"table-bookings-page */ `../component/tableBookings/index`),
    path: '/table-bookings',
  },
  {
    name: 'TableBooking',
    location: '/profile/table-booking',
    comp: () =>
      import(
        /* webpackChunkName:"table-booking-page */ `../component/tableBookings/tableBookingInfo`
      ),
    path: '/table-booking/:reservation_id',
  },
  {
    name: 'TableReservationPayment',
    location: '/profile/table-reservation-payment',
    comp: () =>
      import(
        /* webpackChunkName:"table-revservation-page */ `../component/tableReservationPayment/index`
      ),
    path: '/reservation',
  },
  {
    name: 'OrderSummary',
    location: '/profile/orderSummary',
    comp: () =>
      import(/* webpackChunkName:"order-summary-page" */ `../component/profile/orderSummary`),
    path: '/orderSummary/order_id/:order_id',
  },
  {
    name: 'FoodCourt',
    location: '/FoodCourt',
    comp: () => import(/* webpackChunkName:"foodcourt-page" */ `../component/FoodCourt`),
    path: '/fc/:mall',
  },
  {
    name: 'PaymentResponse',
    location: '/Payments/Responses',
    comp: () =>
      import(/* webpackChunkName:"payment-response-page" */ `../component/Payments/Responses`),
    path: '/payment/response',
  },
  {
    name: 'Home',
    location: '/home/home',
    comp: () => import(/* webpackChunkName:"home-page" */ `../component/home/home`),
    path: '/home',
    exact: true,
  },

  {
    name: 'pageNotFound',
    location: '/common/pageNotFound',
    comp: () => import(/* webpackChunkName:"page-not-found */ `../component/common/pageNotFound`),
    exact: true,
  },
];
