import {
  CART_LOADER,
  DELETE_CART,
  DELETE_CART_BILL,
  UPDATE_CART,
  UPDATE_CART_BILL,
  UPDATE_LOYALITY,
} from './cartTypes';
const intialState = {
  cart: {},
  cartBill: {},
  loyality: {},
  loaded: false,
};

export const fetchCart = (state = intialState, action) => {
  switch (action.type) {
    case CART_LOADER: {
      return { ...state, loaded: false };
    }
    case UPDATE_CART: {
      return {
        ...state,
        loaded: true,
        cart: action.payload,
      };
    }
    case UPDATE_CART_BILL: {
      return {
        ...state,
        cartBill: action.payload,
      };
    }
    case DELETE_CART:
      return {
        ...state,
        cart: {},
      };
    case DELETE_CART_BILL:
      return {
        ...state,
        cartBill: {},
      };

    case UPDATE_LOYALITY:
      return {
        ...state,
        loyality: action.payload,
      };

    default:
      return { ...state };
  }
};

export default fetchCart;
