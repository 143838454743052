export default (action, { error, ...rest }) => {
    if (!window.newrelic) return;
  
    switch (action) {
      case "error":
        window.newrelic.noticeError(error, rest || {});
        break;
      default:
        break;
    }
  };