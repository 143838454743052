// To Fetch Restaurants near me
export const GET_NEAR_BY_RESTAURANT = `/api/restaurant/details/get_nearby_restaurants.php`;
// To fetch Restaurant Settings
export const GET_REST_SETTING = `/api/restaurant/details/get.php`;
// To fetch restaurant Menu
export const GET_REST_MENU = '/api/menu';
// To get Discount Coupon
export const GET_COUPON = '/api/discounts/get.php';

export const GET_IMAGE_PATH_FOR_ITEM_DESCRIPTION_POPUP =
  '/api/restaurant/menu/dish/get_item_images.php';
export const OTP = '/user_login/send_otp.php';
export const VERIFY_TOKEN = '/user/auth_token_customer.php';
export const SEND_OTP = '/v1/user/send_otp';
export const VERIFY_OTP = '/v1/user/verify_otp';
export const RESEND_OTP = '/v1/user/resend_otp';
export const GET_TOKEN = '/user_login/generate_token.php';
export const SIGNUP = '/user_login/register_user.php';
export const GUEST_SIGN_IN = '/api/user/register/guest';
export const REPLACE_GUEST = '/api/user/replace/guest';
export const GET_CART = '/api/orders/cart/get.php';
export const ORDER_STATUS = '/api/orders/details/get.php';
export const RESTAURANT_DETAILS = 'api/restaurant/details/get.php';
export const MQTT_URL = 'wss://mqtt.easyeat.ai:2002/mqtt';
export const CANCEL_ORDER = '/api/orders/details/cancel_order.php';
export const PAYMENT_METHODS = 'api/payment/get_payment_methods.php';
export const RAZORPAY = 'api/payment/initiate_razerpay_payment.php';
export const INITIATE_PAYMENT_EP = '/api/v1/payment/initiate';
export const INITIATE_PAYMENT_EP_FC = '/api/v1/payment/initiate/foodcourt';
export const INITIATE_PAYMENT_V3 = '/api/v3/payment/initiate';
export const PAYMENT_STATUS_EP = '/api/v1/payment/check-payment-status';
export const PAYMENT_STATUS_COMMON = '/api/v1/payment/get-payment-detail';
export const PAYMENT_STATUS_END_POINT = '/api/orders/';
export const MALL_RASTAURANT = 'api/food-courts/fc/';
export const GET_MALL_RASTAURANTS = 'api/restaurant/details/get_mall_restaurants.php';
// To Update Cart Item
export const UPDATE_CART_ITEMS = '/api/orders/cart/update.php';
export const GET_USER_PROFILE = '/user_profile_new/get_basic_user_profile.php';
export const GET_USER_DETAILS = '/api/user/info';
export const QUICK_SERVICES = 'api/restaurant/details/get_restaurant_services.php';
export const SAVE_USER = '/user_profile_new/update_user_details.php';
export const NUTRITION = 'user_profile_new/get_nutrition_daily.php';
export const SAVINGS = 'user_profile_new/get_earning_saving_total.php';
export const ORDERHISTORY = '/user_profile_new/get_user_orders.php';
export const SCHEDULEORDER = 'api/orders/details/get_schedule_orders.php';
export const GET_ORDER = 'user_profile_new/get_order_details.php';
export const EMAIL = '/api/user/email-invoice';
export const LOGOUT = 'user_login/signout.php';
// Special Note for items
export const UPDATE_CART_NOTE = '/api/orders/cart/update_special_note.php';
// Update Cart Bill
export const UPDATE_CART_BILL_API = '/api/orders/cart/get_cart_bill.php';

export const GET_MALL_CART = 'api/orders/cart';
export const FOOD_COURT_ORDERS = 'api/orders/food-court-orders';
export const EMAIL_INVOICE = 'api/user/email-invoice';
export const DELETE_ALLERGIES = 'user_profile_new/allergies/delete_user_allergy.php';
export const ALLERGY_FILLTER = '/user_profile_new/allergies/get_allergies_filter.php';
export const ADD_ALLERGY = 'user_profile_new/allergies/add_allergy.php';
export const NO_ALLERGY = '/user_profile_new/allergies/update_user_allergies.php';
export const GET_USER_ADDRESS = '/user_profile_new/address/get.php';
export const UPDATE_DISCOUNT_COUPON = '/api/orders/cart/update_discount.php';
export const COOKING_NOTES = 'api/orders/cart/notes';
export const EARNING = '/user_profile_new/get_earning_savings_daily.php';
export const QUICK_SERVICES_MESSAGE = 'messaging/send_text_message.php';
export const ADD_USER_ADDRESS = 'user_profile_new/address/create.php';
export const GET_DISTANCE_MATRIX = 'get_distance.php';
export const CREATE_SCHEDULED_ORDER = '/api/orders/details/create_schedule_order.php';
// SocialMedia connect
export const FACEBOOK_CONNECT = '/user_login/fsignin/linkfacebook.php';
export const GOOGLE_CONNECT = '/user_login/gsignin/linkgoogle.php';
export const DEMO = '/website/mail.php';
export const CREATE_ORDER = '/api/orders/details/create.php';
export const SET_DEFAULT_USER_ADDRESS = '/user_profile_new/address/set_default.php';
export const SEND_FEEDBACK = '/api/review/create.php';
export const GET_LOYALITY_BENEFIT = '/loyalty/get_loyalty_benefits.php';
export const ADD_REDEEM_REWARD_TO_CART = '/loyalty/add_redeemed_item_cart.php';
export const GET_CREDIT_SCORE = '/api/credit';
export const GET_PROMOTIONS = 'api/promotion/coupons';
// Get Orders by Table -Mergge Bill
export const GET_ORDERS_BY_TABLE = '/api/orders/details/get_orders_by_table.php';
export const GET_ALL_ORDERS = '/api/orders/details/get_orders.php';

export const CASH_PAY_ORDER = '/messaging/put.php';
export const CASH_PAY_ORDER_KIOSK = '/api/kiosk/kiosk-offline-order';
export const DIRECT_PAYMENT = '/api/orders/billing/direct_payment.php';

export const GET_USER_ORDER_LIST = '/api/orders/details/get_orders_list.php';

export const REMOVE_UNAVAILABLE_ITEMS = 'api/orders/cart/remove_unavailable_items.php';

export const FCM_SEND_MESSAGE = '/api/fcm/send_message.php';

export const GET_TABLE_NUMBER = '/api/restaurant/new';
export const GET_LOYALITY_CASHBACK = '/wallet-amount-by-user/';
export const GET_CASHBACK = '/api/rest/wallet/user/restaurant';
export const REDEEM_LOYALTY = '/api/credit/loyalty';

export const TRACK_ORDER_TYPE = '/api/orders/cart/update_cart_details.php';
export const MALL_URL = '/api/food-courts/fc/';

// Referral
export const REFERRAL_CODE = '/api/discount-coupons/referral';
export const REFERRER_CODE = '/api/discount-coupons/referral/referrer-code';
export const REFERRAL_INFO = '/api/discount-coupons/referral/details';
export const OTPLESS_USERDETAILS = '/api/otpless/userdetails';

export const USER_TABLE_CHANGE = 'api/orders/user-table-update';
export const FC_COUPONS = '/api/fc/coupons/active';
export const APPLY_FC_COUPONS = '/api/fc/coupons/apply';
export const REMOVE_FC_COUPONS_REST = '/api/fc/coupons/remove-rest';
export const REMOVE_FC_COUPONS_ALL = '/api/fc/coupons/remove-all';
export const FC_LOYALTY_UPDATE = '/api/food-court-loyalty/update';

// Table reservation Urls
export const GET_TABLE_BOOKINGS = '/api/v1/reservation/user';
export const TR_GET_TIME_SLOTS = '/api/v1/reservation/check-availability';
export const CREATE_RESERVATION = '/api/v1/reservation/create';
export const MODIFY_RESERVATION = '/api/v1/reservation/request-modification';
export const GET_TABLE_BOOKING_BY_ID = '/api/v1/reservation/fetch';
export const CANCEL_RESERVATION = '/api/v1/reservation/request-cancellation';
export const GET_RESERVATION_BY_PAYMENT_ID = '/api/v1/reservation/payment-id';
export const GET_RESERVATION_REFUND_BY_ID = '/api/generic-refunds/reservation-refund-status';
