import { createContext } from 'react';

const GlobalContext = createContext({
  loading: false,
  setLoading: () => {
    /*initially,setLoading is Empty */
  },
});

export default GlobalContext;
