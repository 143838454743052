import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { getSessionItem } from '../webStorage/sessionStorage';

const detectionOptions = {
  order: ['sessionStorage'],

  lookupSessionStorage: 'i18nextLng',
};

const fallbackLng = ['en'];
const availableLanguages = ['en', 'id', 'my'];
const subdomainLng = window.location.hostname.split('.').pop();
const sessionLanguage = getSessionItem('i18nextLng');
const currentLanguage = subdomainLng == 'ai' ? fallbackLng : subdomainLng;

i18n
  .use(LngDetector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng,
      lng: sessionLanguage || currentLanguage,
      debug: true,
      whitelist: availableLanguages,
      detection: detectionOptions,

      resources: {
        en: {
          translations: require('./locales/en/en.json'),
        },
        id: {
          translations: require('./locales/id/id.json'),
        },
        my: {
          translations: require('./locales/my/my.json'),
        },
      },
      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        wait: true,
        // useSuspense: true,
      },
    },
    (err, t) => {
      if (err) console.error(err);
    }
  );

export default i18n;
