import { CREATE_ORDER, CREATE_SCHEDULED_ORDER } from '../../axios/appiendPoints';
import { axiosInstance } from '../../axios/axios';
import { DINEIN_TYPE, PICKUP_TYPE, TAKEAWAY } from '../../webStorage/keys/sessionKeys';
import { getOrderTypeSession } from '../../webStorage/manageOrderType';
import { getPayLater } from '../../webStorage/sessionStorage';
import { redirectPathGenerator } from '../../component/Payments/functions/redirectPathGenerator';
import {
  FETCH_CREATE_ORDER_FAILURE,
  FETCH_CREATE_ORDER_REQUEST,
  FETCH_CREATE_ORDER_SUCCESS,
  REMOVE_LOADER,
} from './createOrderTypes';
import { getUrl } from '../../component/menu/Functions/generateLoginUrl';

export const createOrderRequest = () => {
  return {
    type: FETCH_CREATE_ORDER_REQUEST,
  };
};

export const createOrderSuccess = (order) => {
  return {
    type: FETCH_CREATE_ORDER_SUCCESS,
    payload: order,
  };
};

export const createOrderFailure = (error) => {
  return {
    type: FETCH_CREATE_ORDER_FAILURE,
    payload: error,
  };
};
export const removeOrderLoader = (payload) => {
  return { type: REMOVE_LOADER, payload };
};
// creating order
export const createOrderAction = (
  params,
  history,
  bill,
  orderType,
  isScheduledOrder,
  eligibleForPerPaidOrder,
  mall = ''
) => {
  return (dispatch) => {
    const url = isScheduledOrder ? CREATE_SCHEDULED_ORDER : CREATE_ORDER; //Scheduled Order or Order ASAP
    axiosInstance
      .post(url, params)
      .then((response) => {
        /* This is a hack and it needs to be removed */

        /* Start of hack */

        if (response?.data?.message?.toLowerCase() == 'invalid token') {
          const loginUrl = getUrl();
          history.push(`/login?${loginUrl}`);
        }

        /* End of hack */

        if (response.data.status == 0) {
          dispatch(removeOrderLoader(response.data?.message || 'something_went_wrong'));
          // toastError(response.data?.message || 'Something went wrong!');
          return;
        }
        const order = response.data.data;
        dispatch(createOrderSuccess(order?.order_id));
        const sessionSetting = getOrderTypeSession();
        if (
          orderType == DINEIN_TYPE &&
          (!eligibleForPerPaidOrder?.prePaidEnabledfromShannon ||
            !(
              eligibleForPerPaidOrder?.prePaidEnabledfromShannon &&
              eligibleForPerPaidOrder?.enabledForOrderType
            ))
        ) {
          history.replace(
            `/payment/response?status=1&order_id=${order?.order_id}&type=dinein&redirect=1`
          );
        } else if (
          orderType == PICKUP_TYPE &&
          sessionSetting?.pickupType == TAKEAWAY &&
          getPayLater(order?.order_id) == '1' &&
          (!eligibleForPerPaidOrder?.prePaidEnabledfromShannon ||
            !(
              eligibleForPerPaidOrder?.prePaidEnabledfromShannon &&
              eligibleForPerPaidOrder?.enabledForOrderType
            ))
        ) {
          history.push(
            `/payment/response?status=1&order_id=${order?.order_id}&type=addmore&redirect=1`
          );
        } else if (bill === 0) {
          return history.push(
            redirectPathGenerator(bill, order?.order_id, mall, 'loyalty_cashback')
          );
        } else {
          history.push(`/payments/${order?.order_id}/${bill}`);
        }
      })
      .catch((err) => {
        console.log('Error:', err);
        dispatch(createOrderFailure(err.message));
      });
  };
};
