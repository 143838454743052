import Axios from 'axios';
import { LANGUAGE_ENUMS, getCountryCode } from '../functions/getCountryCode';
import { getSessionItem } from '../webStorage/sessionStorage';
const countryLanguage = getCountryCode() === 'id' ? 'id' : 'en';
const currentLanguage = getSessionItem('i18nextLng') || countryLanguage;
const locale = LANGUAGE_ENUMS[currentLanguage].acceptLanguage;

// Function to update the accept-language header
export const updateAcceptLanguageHeader = (locale) => {
  // Update the value of the accept-language header in the axios instance
  axiosInstance.defaults.headers['accept-language'] = locale;
  axiosInstanceNext.defaults.headers['accept-language'] = locale;
  axiosHasura.defaults.headers['accept-language'] = locale;
  axiosPayment.defaults.headers['accept-language'] = locale;
  axiosOTPService.defaults.headers['accept-language'] = locale;
  axiosReservation.defaults.headers['accept-language'] = locale;
};

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_PHP_API_BASE_URL,
  timeout: 25000,
  headers: {
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
    'Access-Control-Allow-Origin': '*',
  },
});
// export const axiosInstance = Axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
export const axiosInstanceNext = Axios.create({
  baseURL: process.env.REACT_APP_NEXT_API_BASE_URL,
  timeout: 10000,
  headers: {
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
  },
});
export const axiosHasura = Axios.create({
  baseURL: process.env.REACT_APP_HASURA_URL,
  timeout: 10000,
  headers: {
    'x-hasura-admin-secret': process.env.REACT_APP_SECRET_KEY,
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
  },
});
export const axiosPayment = Axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
  timeout: 30000,
  headers: {
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
    'Access-Control-Allow-Origin': '*',
  },
});

export const axiosOTPService = Axios.create({
  baseURL: process.env.REACT_APP_OTP_SERVICE_URL,
  timeout: 30000,
  headers: {
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
    'Content-Type': 'application/json',
  },
});

export const axiosReservation = Axios.create({
  baseURL: process.env.REACT_APP_RESERVATION_URL,
  timeout: 30000,
  headers: {
    'country-code': getCountryCode() === 'id' ? 'ID' : 'MY',
    'accept-language': locale,
  },
});
