import { getUserId, isGuestUser } from '../webStorage/localStorage';
import { isUserLoggedIn } from './isUserLoggedIn';
import queryString from 'query-string';
import { getTableNumber } from './getTableNumber';
import { GET_REST_SETTING } from '../axios/appiendPoints';
import { PICKUP_TYPE } from '../webStorage/keys/sessionKeys';
import { qrTrack } from '../analytics/tracker/events/qrEvents';
import { axiosInstance } from '../axios/axios';

const userLoggedInState = () => {
  if (isGuestUser()) return 'GUEST';
  if (isUserLoggedIn()) return 'LOGGED IN';
  return 'NOT LOGGED IN';
};

export const externalQrScan = async (data) => {
  let redirectedURL = '/qrError';

  if (!data) return { redirectedURL };

  const userId = getUserId();
  data = data.replace('?', '?&');
  data = data.replace(/"/g, '');
  let queries = queryString.parse(data);

  let restaurantIdFromUrl = queries.restaurant_id;
  let tableIdFromUrl = queries.tid;

  let tableNo;
  let qrOrderingPlan = 0;

  const isFoodcourt = data.includes('/fc/');
  //Set table id in session
  const ot = Number(queries?.ot || 1);

  if (!isFoodcourt && queries?.tid && (!queries?.ot || queries.ot == 1)) {
    const { displayTable, error } = await getTableNumber({
      restaurantId: queries.restaurant_id,
      tableId: queries.tid,
    });
    if (error) {
      // qrTrack({
      //   QR_Scan_Attempt: 'failed',
      //   User_logged_in_state: userLoggedInState(),
      //   restaurant_id: restaurantIdFromUrl,
      //   qr_table_no: displayTable,
      //   table_id: tableIdFromUrl,
      //   user_id: userId,
      // });
      return { redirectedURL };
    }
    tableNo = displayTable;
  }

  try {
    if (queries.restaurant_id) {
      let restSettingParams = new URLSearchParams();
      restSettingParams.append('restaurant_id', queries.restaurant_id);
      const response = await axiosInstance.post(GET_REST_SETTING, restSettingParams);
      qrOrderingPlan = response?.data?.data?.settings?.global?.qr_ordering_plan ?? 0;

      const nameId = response.data.data?.nameid;
      if (nameId) {
        // qrTrack({
        //   QR_Scan_Attempt: 'Success',
        //   User_logged_in_state: userLoggedInState(),
        //   restaurant_id: restaurantIdFromUrl,
        //   qr_table_no: tableNo,
        //   table_id: tableIdFromUrl,
        //   restaurant_name: nameId,
        //   user_id: userId,
        // });

        if (queries?.setting) {
          redirectedURL = `/r/${nameId}/${PICKUP_TYPE}`;
        } else if (queries?.type == 'tk') {
          redirectedURL = `/r/${nameId}/3/ta`;
        } else {
          redirectedURL = `/r/${nameId}${
            ot == 1
              ? '/' + `${ot}?source=qr&tableId=${tableIdFromUrl}&tableNo=${tableNo}`
              : `/${ot}`
          }`;
        }
      }
    }
  } catch (err) {
    console.log('Error', err);
  }
  return { redirectedURL, tableIdFromUrl, tableNo, qrOrderingPlan };
};
