import { DELIVERY_TYPE, PICKUP_TYPE } from '../webStorage/keys/sessionKeys';
import { getCountryCode } from './getCountryCode';

export const getEligibleOrderTypes = () => {
  const countryCode = getCountryCode();
  if (countryCode == 'id') {
    //Delivery is disabled
    return 5;
  }
  return 7;
};

export const getDefaultOrderType = () => {
  const eligibleOrderType = getEligibleOrderTypes();
  if ((eligibleOrderType & 2) === 2) {
    return DELIVERY_TYPE;
  }
  return PICKUP_TYPE;
};
