import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/index.css';
import './i18n/config';


const i18nextOptions = {
  // order and from where user language should be detected
  order: ['sessionStorage'],

  // keys or params to lookup language from
  // lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  // lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  lookupFromSubdomainIndex: 0,

  // lookupFromPathIndex: 0,

  // cache user language on
  caches: ['sessionStorage', 'localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18next.use(LanguageDetector).init({
  detection: i18nextOptions,
});

const languageDetector = new LanguageDetector();
languageDetector.init(i18nextOptions);

ReactDOM.render(
  <React.StrictMode>
    {/* <Online> */}
    <App />
    {/* </Online> */}
    {/* <Offline> */}
    {/* <OfflineApp /> */}
    {/* </Offline> */}

    {/* </Scrollbars> */}
  </React.StrictMode>,
  document.getElementById('root')
);
