import {
  FETCH_REST_SETTING_REQUEST,
  FETCH_REST_SETTING_SUCCESS,
  FETCH_REST_SETTING_FAILURE,
  RESET_STORES,
} from './restSettingTypes';

const intialState = {
  loading: true,
  Setting: {},
  error: '',
};

const fetchRestaurantSetting = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_REST_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REST_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        Setting: action.payload,
        error: '',
      };
    case FETCH_REST_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        Setting: {},
        error: action.payload,
      };
    case RESET_STORES:
      return intialState;

    default:
      return { ...state };
  }
};

export default fetchRestaurantSetting;
