import React from 'react';
import './app-loader.css';

export default function AppLoader() {
  return (
    <div className='d-flex container_loader'>
      <div className='pan-loader'>
        <div className='loader'></div>
        <div className='pan-container'>
          <div className='pan'></div>
          <div className='handle'></div>
        </div>
      </div>
    </div>
  );
}
