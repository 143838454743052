import { returnObjectKey } from './returnObjectKey';

function get_weekday_timings(config_timings) {
  let start = 0;
  let end = 0;
  let timings = {};
  let isValid = 0;
  //   let config_timings_obj = {};
  let weekSlot = [[], [], [], [], [], [], []];
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 1440; j = j + 30) {
      weekSlot[i].push({ valid: 0, start: j, end: j + 30 });
    }
  }
  //   for (let i = 0; config_timings && i < config_timings.length; i++) {
  //     config_timings_obj[config_timings[i]['id']] = config_timings[i];
  //   }
  for (let i = 0; i < 48; i++) {
    for (let k = 0; k < 7; k++) {
      for (
        let j = 0;
        config_timings && config_timings[k]?.is_enabled && j < config_timings[k]?.slot?.length;
        j++
      ) {
        timings = config_timings[k].slot[j];
        isValid = 1;
        start = i * 30;
        end = start + 30;
        if (
          (timings.end >= end && timings.start >= end) ||
          (timings.start <= start && timings.end <= start)
        )
          isValid = 0;

        if (isValid) {
          if (start < timings.start) start = timings.start;
          if (end > timings.end) end = timings.end;
          if (end - start < 15) {
            weekSlot[k][i]['valid'] = 0;
          } else {
            weekSlot[k][i]['valid'] = 1;
          }
          weekSlot[k][i]['start'] = start;
          weekSlot[k][i]['end'] = end;
          break;
        }
      }
    }
  }
  return weekSlot;
}
function copy(x) {
  return JSON.parse(JSON.stringify(x));
}
function is_day_active(timings) {
  for (const timing of timings) {
    if (timing['valid']) {
      return 1;
    }
  }
  return 0;
}
function cal_month_data(start_date, diffDays, weekday_timing) {
  let month_data = new Array(45);
  // let date = new Date();
  let currDate = '';
  let currDay = '';
  let start_time = start_date.getTime();
  let weektime = weekday_timing;

  for (let i = 0; i < 45; i++) {
    let date = new Date();
    // date.setMonth(start_date.getMonth());
    // date = new Date(date.setDate(start_date.getDate() + i));

    // adding time in current date to get dates
    date = new Date(start_date.getTime() + i * 24 * 3600 * 1000);
    currDay = date.getDay();
    currDate = date.getDate();
    if (currDay == 0) currDay = 7;
    currDay -= 1;
    let time_today = copy(weektime[currDay]);
    // let active_day = 1;
    let active_day = is_day_active(time_today);
    month_data[i] = {
      timings: time_today,
      date: currDate,
      day: currDay,
      active_day: active_day,
      datetime: date,
    };
  }

  for (let i = 0; i <= diffDays; i++) {
    let date = new Date();
    // date.setMonth(start_date.getMonth());
    // date = new Date(date.setDate(start_date.getDate() + i));

    // adding time in current date to get dates
    date = new Date(start_date.getTime() + i * 24 * 3600 * 1000);
    let time_etoday = date.setHours(0, 0, 0, 0);
    let active_day = 1;
    if (start_time > time_etoday) {
      let unactive_hours = parseInt((start_time - time_etoday) / 3600000);
      unactive_hours = unactive_hours * 2; //As time slots are set to 30 mins
      if (unactive_hours >= 48) {
        active_day = 0;
        unactive_hours = 48;
      }
      for (let j = 0; j < unactive_hours; j++) {
        month_data[i]['timings'][j]['valid'] = 0;
      }
      let temp_active_day = 0;
      for (let j = 0; j < 48; j++) {
        if (month_data[i]['timings'][j]['valid']) {
          temp_active_day = 1;
        }
      }
      month_data[i]['active_day'] = temp_active_day;
    }
  }
  return month_data;
}

// * https://stackoverflow.com/questions/63353546/how-to-get-a-utc-offset-from-a-timezone-in-js
function getUTCOffset(date, timeZone) {
  const dateComponents = date.toLocaleString('sv', { timeZone }).split(/[\-\s:]/);
  // Months are zero-based in JavaScript
  dateComponents[1] = dateComponents[1] - 1;
  const utcDate = Date.UTC(...dateComponents);
  return Math.round((date - utcDate) / 60 / 1000);
}

const getTimezoneOffset = (timeZone, date = new Date()) => {
  let offset = Math.abs(getUTCOffset(new Date(), timeZone) * 6000);
  if (isNaN(offset)) {
    return 480 * 60000;
  } else {
    return offset;
  }
};

export default function next_thrty_days(rest_config_data, orderType) {
  if (!rest_config_data?.rest_timings) return [];
  const ORDER_TYPE = returnObjectKey(orderType);
  let config_timings = rest_config_data?.rest_timings[ORDER_TYPE];
  let next_open_epoch = parseInt(rest_config_data.next_open_epoch) * 1000;
  let timezone = rest_config_data.time_zone;
  let start_time = Math.floor(Date.now()) + 3600000 - (Math.floor(Date.now()) % 3600000);
  if (start_time - Math.floor(Date.now()) < 1800000) {
    start_time += 3600000;
  }
  let curr_time = Math.floor(Date.now()) + 3600000 - (Math.floor(Date.now()) % 3600000);
  next_open_epoch = next_open_epoch + 3600000 - (next_open_epoch % 3600000);
  if (next_open_epoch > start_time) {
    start_time = next_open_epoch;
  }
  let rest_offset = 0;
  let user_offset = 0;
  try {
    rest_offset = parseInt(getTimezoneOffset(timezone));
  } catch (error) {
    rest_offset = 480 * 60000;
  }
  try {
    user_offset = parseInt(getTimezoneOffset());
  } catch (error) {
    user_offset = 480 * 60000;
  }
  var curr_date = new Date(curr_time + (rest_offset - user_offset));
  var start_date = new Date(start_time + (rest_offset - user_offset));
  const diffTime = Math.abs(curr_date - start_date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (!config_timings) {
    config_timings = {};
  }

  let weekday_timings = get_weekday_timings(config_timings);
  let thirty_days = cal_month_data(start_date, diffDays, weekday_timings);
  return thirty_days;
}

export function get_time_range(start, end) {
  let stime = '';
  let etime = '';
  let shour = parseInt(parseInt(start) / 60);
  let smin = leftPad(parseInt(start) % 60, 2);
  let ehour = parseInt(parseInt(end) / 60);
  let emin = leftPad(parseInt(end) % 60, 2);
  if (shour == 0) stime = '12:' + smin + ' AM';
  else if (shour == 12) stime = '12:' + smin + ' PM';
  else if (shour == 24) stime = '12:' + smin + ' AM';
  else if (shour > 12) stime = shour - 12 + ':' + smin + ' PM';
  else stime = shour + ':' + smin + ' AM';

  if (ehour == 0) etime = '12:' + emin + ' AM';
  else if (ehour == 12) etime = '12:' + emin + ' PM';
  else if (ehour == 24) etime = '12:' + emin + ' AM';
  else if (ehour > 12) etime = ehour - 12 + ':' + emin + ' PM';
  else etime = ehour + ':' + emin + ' AM';

  return stime + ' - ' + etime;
}
function leftPad(number, targetLength) {
  var output = number + '';
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return output;
}

export function calculateOrderTime(rest_config_data, date, starttime) {
  let timezone = rest_config_data.time_zone;
  let rest_offset,
    user_offset,
    date_start = '';
  try {
    rest_offset = parseInt(getTimezoneOffset(timezone));
  } catch (error) {
    rest_offset = 480 * 60000;
  }
  try {
    user_offset = parseInt(getTimezoneOffset());
  } catch (error) {
    user_offset = 480 * 60000;
  }

  date_start = date.setHours(parseInt(starttime / 60), starttime % 60);
  const actual_date = new Date(date_start - (rest_offset - user_offset));

  return actual_date.getTime() / 1000;
}
