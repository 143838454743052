import { getCartTokenLS, setCheckCartToken } from "../../../functions/getCartToken";
import { getLocalItem, setLocalItem } from "../../../webStorage/localStorage";
import { getCurrentRestaurant, getOrderTypeSession } from "../../../webStorage/manageOrderType";
import { getMenuPath, getSessionItem } from "../../../webStorage/sessionStorage";

export const getUrl = () => {
    let otplessShow = getLocalItem('otpLess') || '/profileHome';
    const cartTokenFromLS = getCartTokenLS();
    let token = setCheckCartToken(cartTokenFromLS);
    let qr = getSessionItem('scanThroughQr');
    let menu_path = getMenuPath();
    let language = getSessionItem('i18nextLng') || 'en';
    const sessionId = getSessionItem('sessionId');
    getSessionItem('history-array') &&
      setLocalItem('history-array', getSessionItem('history-array'));
    getCurrentRestaurant &&
      setLocalItem(getCurrentRestaurant(), getSessionItem(getCurrentRestaurant()));

    // Persisting scheduled order details for otpless login
    const scheduledString = getStoreScheduledString();

    let url = `otpless=true&browser=${getLocalItem(
      'browser'
    )}&scanThroughQr=${qr ? qr : ''}&menu_path=${menu_path ? menu_path : ''}&path=${
      otplessShow ? otplessShow : ''
    }&cartToken=${
      token ? token : ''
    }&sessionId=${sessionId}&language=${language}${scheduledString}`;
    return url;
};

const getStoreScheduledString = () => {
    const orderTypeDetails = getOrderTypeSession();
    return `${orderTypeDetails?.date ? '&date=' + orderTypeDetails?.date : ''}${
      orderTypeDetails?.epoch ? '&epoch=' + orderTypeDetails?.epoch : ''
    }${orderTypeDetails?.orderTime ? '&orderTime=' + orderTypeDetails?.orderTime : ''}${
      orderTypeDetails?.time ? '&time=' + orderTypeDetails?.time : ''
    }${orderTypeDetails?.selectedTime ? '&selectedTime=' + orderTypeDetails?.selectedTime : ''}`;
  };