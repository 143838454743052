export const getCountryCode = () => window.location.hostname.split('.').pop();

export const COUNTRY_CODE_ENUMS = {
  id: 'ID',
  ai: 'MY',
};

export const LANGUAGE_ENUMS = {
  en: {
    acceptLanguage: 'en-US',
    languageCode: 'en',
    name: 'English',
  },
  id: {
    acceptLanguage: 'id-ID',
    languageCode: 'id',
    name: 'Bahasa',
  },
  my: {
    acceptLanguage: 'en-US',
    languageCode: 'my',
    name: 'Malay',
  },
};
