import Publisher from "../../utils/publisher";
import newRelicLogger from "./new-relic-logger";

let errorLoggerInstance = null;

const parseJSON = (...args) => {
  try {
    return JSON.parse(...args);
  } catch (error) {
    return false;
  }
};

export default function logger() {
  if (!errorLoggerInstance) {
    const enableNewRelic = process.env.REACT_APP_EASYEAT_ENABLE_NEW_RELIC;

    errorLoggerInstance = new Publisher();

    if (enableNewRelic) errorLoggerInstance.subscribe(newRelicLogger);
  }

  return errorLoggerInstance;
}