import {
  calculateOrderTime,
  get_time_range,
} from '../component/popUp/ScheduledOrder/Functions/ScheduledOrder';
import { SCHEDULED } from '../webStorage/keys/sessionKeys';
import { safeNumber } from './safeNumber';

export const scheduledTimeFromUrl = (data, restaurant) => {
  if (!data?.date || !data?.slot) {
    return {};
  }
  const date = new Date(data.date);
  const start = safeNumber(data.slot) * 60;
  const end = start + 30;
  const epoch = calculateOrderTime(restaurant, date, start);
  const time = get_time_range(start, end);
  return { orderTime: SCHEDULED, date, time, epoch };
};

export const scheduledTimeFromUrlOtpless = (data) => {
  if (!data?.date) {
    return {};
  }
  const date = new Date(data.date);
  const epoch = data.epoch;
  const time = data.time;
  const selectedTime = safeNumber(data.selectedTime);
  return { orderTime: SCHEDULED, date, time, epoch, selectedTime };
};
