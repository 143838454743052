const randomStringEnums = {
  characters: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  platform: 'user',
};

export const generateRandomString = (length = 10) => {
  const characters = randomStringEnums.characters;
  const platform = randomStringEnums.platform;
  const timeStamp = new Date()
    .toISOString()
    .replace(/[^0-9]/g, '')
    .substring(0, 12);
  const charactersLength = characters.length;
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return platform + randomString + timeStamp;
};
