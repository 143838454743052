import React from 'react';
import './spinner.css';

const Spinner = ({
  background = 'transparent',
  addBg = false,
  fixed = false,
  spinnerColor = '#ffffff',
  size = '40px',
  stroke = '6.4px',
}) => {
  return (
    <div className={fixed ? 'spinner__container' : ''} style={{ background }}>
      {addBg ? (
        <div className='spinner__bg'>
          <div
            className='spinner'
            style={{
              background: `radial-gradient(farthest-side, ${spinnerColor} 94%, #0000) top/${stroke} ${stroke} no-repeat, conic-gradient(#0000 30%, ${spinnerColor})`,
              WebkitMask: `radial-gradient(farthest-side, #0000 calc(100% - ${stroke}), #000 0)`,
              height: size,
              width: size,
            }}></div>
        </div>
      ) : (
        <div
        className='spinner'
          style={{
            background: `radial-gradient(farthest-side, ${spinnerColor} 94%, #0000) top/${stroke} ${stroke} no-repeat, conic-gradient(#0000 30%, ${spinnerColor})`,
            WebkitMask: `radial-gradient(farthest-side, #0000 calc(100% - ${stroke}), #000 0)`,
            height: size,
            width: size,
          }}></div>
      )}
    </div>
  );
};

export default Spinner;
