//This code exports a function called getNearByRestaurant which takes an object 
//with four properties as its input parameters: page, search, longitude, and latitude. 
//This function sends a POST request to an API endpoint GET_NEAR_BY_RESTAURANT with 
//the provided parameters as URL parameters and request body. 
//The response data is checked for the presence of nearby_restaurants array, 

import { GET_NEAR_BY_RESTAURANT } from '../../axios/appiendPoints';
import { axiosInstance } from '../../axios/axios';

const getNearByRestaurant = async ({page, search, longitude, latitude}) => {
  let response_obj = { restaurant: [], hasMore: false  , search : false};
  const params = new URLSearchParams();
  params.append('location', JSON.stringify([longitude, latitude]));
  params.append(
    'fields',
    JSON.stringify([`name`, `location`, `address`, `city`, `logo`, `nameid`, `status`, `id`])
  );
  params.append('max_distance', 40000000);
  params.append('page_no', page);
  params.append('limit', 10);
  params.append('name_filter', search);
  try {
    const response = await axiosInstance.post(GET_NEAR_BY_RESTAURANT, params);
    if (
      response &&
      response?.data &&
      response?.data?.data &&
      response?.data?.data?.nearby_restaurants &&
      response?.data?.data?.nearby_restaurants.length
    ) {
       response_obj.restaurant = [...response?.data?.data?.nearby_restaurants];
       response_obj.hasMore = response?.data?.data?.page_no < response?.data?.data?.total_pages;
    }
    const trimmedStr = search.trim();
    response_obj.search = trimmedStr;
  } catch (error) {
    response_obj.hasMore = false;
  }
  return response_obj;
};

export default getNearByRestaurant;
