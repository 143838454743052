const timeZoneCityToCountry = {
  Jakarta: 'Indonesia',
  Pontianak: 'Indonesia',
  Makassar: 'Indonesia',
  Jayapura: 'Indonesia',
};

export const getRedirectionLink = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzArr = userTimeZone.split('/');
  const userCity = tzArr[tzArr.length - 1];
  const userCountry = timeZoneCityToCountry[userCity];
  if (userCountry === 'Indonesia') return 'https://try.easyeat.id';
  return 'https://get.easyeat.ai';
};
