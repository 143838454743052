import {
  RESET_RECOMMENDATIONS,
  UPDATE_BEST_SELLER_ITEMS,
  UPDATE_COMPLETE_MEAL_ITEMS,
  UPDATE_FREQUENTLY_ADD_WITH_ITEM,
  UPDATE_RECS_LOADED,
  UPDATE_REPEAT_ITEMS,
} from './types';

const initialState = {
  repeatItems: [],
  bestSellerItems: [],
  completeMealItems: [],
  frequentlyAddWithItem: {},
  recsLoaded: false,
};

const RecommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RECS_LOADED:
      return {
        ...state,
        recsLoaded: true,
      };
    case UPDATE_REPEAT_ITEMS:
      return {
        ...state,
        repeatItems: action.payload,
      };
    case UPDATE_BEST_SELLER_ITEMS:
      return {
        ...state,
        bestSellerItems: action.payload,
      };
    case UPDATE_COMPLETE_MEAL_ITEMS:
      return {
        ...state,
        completeMealItems: action.payload,
      };
    case UPDATE_FREQUENTLY_ADD_WITH_ITEM:
      return {
        ...state,
        frequentlyAddWithItem: { ...state.frequentlyAddWithItem, ...action.payload },
      };
    case RESET_RECOMMENDATIONS:
      return initialState;
    default:
      return { ...state };
  }
};

export default RecommendationsReducer;
