// import { pickup, takeAway } from '../assets/locale/en/commonfolder.json';
import { safeJsonParse } from '../functions/safeJsonParse';
import {
  ASK_TO_USE_APP,
  BACK_PAGE,
  BACK_PATH,
  ERROR_MSG,
  LOGIN_FROM_CART,
  MENU_PATH,
  PAY_LATER,
  PREVIOUS_PATH,
  REDIRECT_URL_STATE,
  SKIP_PACKING_CHARGES,
  TAKEAWAY,
} from './keys/sessionKeys';
import { getCurrentRestaurant, getOrderTypeSession } from './manageOrderType';

export const setSessionItem = (key, value) => {
  sessionStorage.setItem(key, value);
};
export const getSessionItem = (key) => {
  return sessionStorage.getItem(key);
};

export const clearSession = () => {
  sessionStorage.clear();
};
export const removeSessionItem = (key) => {
  sessionStorage.removeItem(key);
};

export const setSkipPackagingCharges = (value) => {
  const currentRestaurant = getCurrentRestaurant();
  setSessionItem(currentRestaurant + SKIP_PACKING_CHARGES, value);
};

export const getSkipPackagingCharges = () => {
  const currentRestaurant = getCurrentRestaurant();
  return getSessionItem(currentRestaurant + SKIP_PACKING_CHARGES) || '0';
};

export const removeSkipPackagingCharges = () => {
  const currentRestaurant = getCurrentRestaurant();
  removeSessionItem(currentRestaurant + SKIP_PACKING_CHARGES);
};

export const setPreviousPath = (path) => {
  if (path) setSessionItem(PREVIOUS_PATH, path);
};
export const getPreviousPath = () => {
  return getSessionItem(PREVIOUS_PATH);
};

export const setMenuPath = (path) => {
  if (path) setSessionItem(MENU_PATH, path);
};
export const getMenuPath = () => {
  return getSessionItem(MENU_PATH);
};

export const setErrorMsg = (msg) => {
  setSessionItem(ERROR_MSG, msg);
};

export const getErrorMsg = () => {
  return getSessionItem(ERROR_MSG);
};

export const removeErrorMsg = () => {
  removeSessionItem(ERROR_MSG);
};

//REDIRECT_URL_STATE is used to change any component initial State when it is being redirected from some other URL
export const setRedirectLinkState = (state) => {
  setSessionItem(REDIRECT_URL_STATE, JSON.stringify(state));
};

export const getRedirectLinkState = () => {
  return JSON.parse(getSessionItem(REDIRECT_URL_STATE));
};

export const removeRedirectLinkState = () => {
  removeSessionItem(REDIRECT_URL_STATE);
};

export const setBackPath = (page, path) => {
  const paths = getBackPath();
  const value = JSON.stringify({ ...paths, [page]: path });
  if (path) setSessionItem(BACK_PATH, value);
};
export const getBackPath = (page) => {
  const paths = safeJsonParse(getSessionItem(BACK_PATH));
  try {
    return page ? paths[page] : paths || {};
  } catch (error) {
    return null;
  }
};
export const setBackPage = (page) => {
  if (page) setSessionItem(BACK_PAGE, page);
};
export const getBackPage = () => {
  return getSessionItem(BACK_PAGE);
};
export const getPickupType = (urlParams) => {
  const sessionSetting = getOrderTypeSession();
  if (sessionSetting?.pickupType == TAKEAWAY || urlParams?.pickupType == TAKEAWAY)
    return 'TakeAway';

  return 'pickup';
};

export const setLoginFromCart = () => {
  setSessionItem(LOGIN_FROM_CART, true);
};
export const isLoginFromCart = () => {
  return getSessionItem(LOGIN_FROM_CART);
};
export const removeLoginFromCart = () => {
  removeSessionItem(LOGIN_FROM_CART);
};

export const setPayLater = (orderId, value) => {
  orderId && setSessionItem(PAY_LATER + orderId, value);
};
export const getPayLater = (orderId) => {
  return getSessionItem(PAY_LATER + orderId);
};

export const setAskToUseApp = () => {
  setSessionItem(ASK_TO_USE_APP, 'POPUP_CLOSED');
};

export const getAskToUseApp = () => {
  return Boolean(getSessionItem(ASK_TO_USE_APP));
};

export const setScheduledPopupVisited = () => {
  const currentRestaurant = getCurrentRestaurant();
  if (currentRestaurant) setSessionItem(currentRestaurant + '_POPUP', 'SET');
};

export const removeScheduledPopupVisited = () => {
  const currentRestaurant = getCurrentRestaurant();
  if (currentRestaurant) removeSessionItem(currentRestaurant + '_POPUP');
};

export const getScheduledPopupVisited = () => {
  const currentRestaurant = getCurrentRestaurant();
  return !!getSessionItem(currentRestaurant + '_POPUP');
};

export const setPaymentChannelSession = (key, value) => {
  setSessionItem(
    'payment-channel',
    JSON.stringify({ ...getPaymentChannelSession(), [key]: value })
  );
};

export const getPaymentChannelSession = () => {
  return JSON.parse(getSessionItem('payment-channel'));
};

export const setHistoryArray = (url) => {
  const historyArray = getHistoryArray() || [];
  if (url === historyArray[historyArray.length - 1]) return;
  historyArray.push(url);
  setSessionItem('history-array', JSON.stringify(historyArray));
};

export const getHistoryArray = () => {
  const paths = safeJsonParse(getSessionItem('history-array'));
  try {
    return paths || [];
  } catch (error) {
    return null;
  }
};

export const setMenuItems = (payload) => {
  setSessionItem('menu-items', JSON.stringify(payload));
};

export const getMenuItems = () => {
  const paths = safeJsonParse(getSessionItem('menu-items'));
  try {
    return paths || [];
  } catch (error) {
    return null;
  }
};
