// reducers/itemsReducer.js

import {
  FETCH_FOODCOURT_RESTAURANTS_REQUEST,
  FETCH_FOODCOURT_RESTAURANTS_SUCCESS,
  FETCH_FOODCOURT_RESTAURANTS_FAILURE,
} from './types';

const initialState = {
  foodcourtRestaurants: [],
  loading: false,
  error: null,
};

const foodcourtRestaurantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOODCOURT_RESTAURANTS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_FOODCOURT_RESTAURANTS_SUCCESS:
      return { ...state, loading: false, foodcourtRestaurants: action.payload, error: null };

    case FETCH_FOODCOURT_RESTAURANTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default foodcourtRestaurantsReducer;
